import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

import ContentPanel from "@components/content-panel"

const IndexPage = () => (
  <Layout>
    <SEO title="Challenges" keywords={[`Ella Luhtasaari`, `Pianist`, `Teacher`, `Music`, `Challenges`]} />
    <div className="main__container">
      <ContentPanel>Loading results...</ContentPanel>
    </div>
  </Layout>
)

export default IndexPage
